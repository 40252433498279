.custom-select {
  > option {
    color: $c-tundora;
    font-weight: initial;
    font-style: normal;
  }
  option[value=""] {
    color: $c-power-grey;
    font-style: italic;
  }
  &[required]:invalid {
    color: $c-power-grey;
    font-style: italic;
  }
}
