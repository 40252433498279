.w {
  &--150 {
    width: 150px;
  }
  &--100 {
    width: 100px;
  }
  &--50 {
    width: 50px;
  }
  &--280 {
    width: 280px;
  }
}
.mw {
  &--91 {
    max-width: 91px;
  }
  &--193 {
    max-width: 193px;
  }
  &--635 {
    max-width: 635px;
  }
  &--556 {
    max-width: 556px;
  }
  &--455 {
    max-width: 455px;
  }
  &--400 {
    max-width: 400px;
  }
  &--300 {
    max-width: 300px;
  }
  &--290 {
    max-width: 290px;
  }
  &--250 {
    max-width: 250px;
  }
  &--130 {
    max-width: 130px;
  }
}

.mh {
  &--hf {
    min-height: calc(100vh - 210px);
  }
  &--24 {
    min-height: 24px;
  }
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.row-highlight {
  background: $c-duck-egg-blue;
}

.zi-0 {
  z-index: 0;
}
