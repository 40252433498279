.custom-checkbox {
  .custom-control-label {
    &::before,
    &::after {
      height: 1.5rem;
      width: 1.5rem;
      top: 0;
    }
  }
}
