.toast {
  max-width: none;
  font-size: initial;
  color: #098543;
  background-color: $c-harp;
  border-color: $c-gum-leaf;
  box-shadow: none;

  &--danger {
    border-color: $c-shilo;
    background-color: $c-we-peep;
    color: $c-crimson;
  }
}
