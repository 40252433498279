// Colors:

// OB Color palette
$c-dark-gray: #444647 !default;
$c-medium-gray: #6d7071 !default;
$c-onebeacon-gray: #898d8e !default;
$c-light-gray: #b8babb !default;
$c-lighter-gray: #d8d8d8 !default;
$c-ultralight-gray: #f8f8f8 !default;
$c-grayish-blue: #406172 !default;
$c-dark-blue: #005d80 !default;
$c-onebeacon-blue: #006fa6 !default;
$c-bright-blue: #008fd6 !default;
$c-dark-green: #4b7c32 !default;
$c-onebeacon-green: #5e9c3f !default;
$c-bright-green: #6dbd46 !default;
$c-onebeacon-red: #cf3a2f !default;
$c-dark-orange: #dc4900 !default;
$c-bright-orange: #f55200 !default;
$c-medium-orange: #ef6c00 !default;
$c-onebeacon-orange: #f58025 !default;
$c-onebeacon-yellow: #ffb81c !default;
$c-brown: #b75f05 !default;
$c-terra-cotta: #d6612b !default;

// Additional Colors from design:
$c-onahau: #cce5ff !default;
$c-french-pass: #b8daff !default;
$c-silver-chalice: #aeaeae !default;
$c-mystic: #d9eaea !default;
$c-jungle-mist: #b2d4d5 !default;
$c-nevada: #6d7071 !default;
$c-geyser: #dde3e8 !default;
$c-concrete: #f2f2f2 !default;
$c-trolley-gray: #808080 !default;
$c-teal: #00626b !default;
$c-lagoon: #007b87 !default;
$c-precious: #03818a !default;
$c-white: #ffffff !default;
$c-power-grey: #a2a3a5 !default;
$c-tundora: #484848 !default;
$c-shilo: #e8aab5 !default;
$c-we-peep: #fcf2f4 !default;
$c-crimson: #c8102e !default;
$c-pacific-blue: #00b3bc !default;
$c-salem: #098543 !default;
$c-gum-leaf: #a9d3bd !default;
$c-harp: #f3f9f5 !default;
$c-apricot: #e5945e !default;
$c-linen: #fdf7f3 !default;
$c-meteor: #db6912 !default;
$c-jagged-ice: #bae1e3 !default;
$c-boulder: #767676 !default;
$c-mine-shaft: #333333 !default;
$c-duck-egg-blue: #f2fbfc !default;
$c-silver-sand: #cacccd !default;

// OB Design System Colors
$c-primary: $c-lagoon;
$c-secondary: $c-lagoon;
$c-secondary-dark: $c-teal;
$c-success: $c-onebeacon-green;
$c-danger: $c-onebeacon-red;
$c-warning: $c-onebeacon-yellow;
$c-info: $c-onebeacon-gray;
$c-light: $c-ultralight-gray;
$c-dark: $c-dark-gray;

// Alert colors
$c-alert-primary: lighten($c-onebeacon-blue, 10%);
$c-alert-secondary: lighten($c-medium-gray, 10%);
$c-alert-success: lighten($c-onebeacon-green, 10%);
$c-alert-danger: lighten($c-onebeacon-red, 10%);
$c-alert-warning: lighten($c-terra-cotta, 10%);
$c-alert-info: lighten($c-onebeacon-gray, 10%);
$c-alert-light: lighten($c-ultralight-gray, 10%);
$c-alert-dark: lighten($c-dark-gray, 10%);

// Fonts
$font-family-helvetica: Helvetica, Arial, sans-serif;
$font-family-IBM-plex: "IBM Plex Sans", Helvetica, Arial, sans-serif;
$font-family-gibson: "canada-type-gibson", Helvetica, Arial, sans-serif;

// Other
$main-menu-bg: $c-teal;
$main-menu-title-bg: #007b87;
$menu-item-font-color: rgba(255, 255, 255, 0.5);
$caution-icon: #d6612b;
$subsection-menu-bg: #f0f0f0;
$policy-status-success: #5e9c3f;
$policy-status-canceled: #cf3a2f;
$policy-status-expired: $c-onebeacon-blue;
$link: #006e77;
$policies-border: 1px solid rgba(0, 0, 0, 0.1);
$policy-ref: #898d8e;
$policy-text-area-counter: #898d8e;
$policy-update-alert-bg: #fff4dc;
$policy-update-alert: #d6612b;
$policy-update-alert-border: #f2cfbf;
$referral-toast-bg: #00a86d;
$referral-toast-error-bg: #cf3a2f;

// Mixin to target touch device hover state. Disable hover styles on touch for specific elements
@mixin touch-hover() {
  .touch &:hover {
    @content;
  }
}
