.alert {
  &__close {
    font-size: 1.2rem;
    color: $c-salem;
  }
  &-info {
    color: $c-teal;
    border-color: $c-gum-leaf;
    background-color: $c-harp;
  }
  &-success {
    color: $c-salem;
    border-color: $c-gum-leaf;
    background-color: $c-harp;
  }
  &-danger {
    color: $c-crimson;
    border-color: $c-shilo;
    background-color: $c-we-peep;
  }
  &-warning {
    color: $c-meteor;
    border-color: $c-apricot;
    background-color: $c-linen;
    &__link {
      color: $c-meteor;
      text-decoration: underline;
      font-weight: normal;
      vertical-align: baseline;
      padding: 0;
      font-family: $font-family-IBM-plex !important;
    }
  }
}

.referralToast {
  position: fixed;
  bottom: 80px;
  right: 15px;
  z-index: 999;

  &__body {
    color: #fff;
    background-color: $referral-toast-bg;
    border-color: $referral-toast-bg;
    box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.4);

    &.error {
      background-color: $referral-toast-error-bg;
      border-color: $referral-toast-error-bg;
    }
  }
}
