.policy {
  &__download {
    font-weight: bold;
    color: $link;
    line-height: 1;
    margin: 0.15rem 0 0.35rem;
  }

  &__updated {
    font-size: 13px;
    line-height: 1;
  }

  &__reference {
    color: $policy-ref;
    margin: auto auto auto 1rem;
  }

  &--charCount {
    font-size: 14px;
    color: $policy-text-area-counter;
  }
}

.additionalInsured {
  &--textarea {
    resize: none;
  }

  &--noInfo {
    color: $c-trolley-gray;
    font-style: italic;
  }
}

.quote {
  &__pennies {
    font-size: 10px;
    font-weight: bold;
  }
}

.venue-code {
  width: 350px;
}
