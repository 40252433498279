.radio-large {
  .custom-control-label {
    font-size: 0.875rem;
    font-weight: normal;
    padding-left: 10px;
    &::before {
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      border-color: #ccc;
      top: -2px;
    }
    &::after {
      border-radius: 50%;
      width: 0.875rem;
      height: 0.875rem;
      left: -19px;
      top: 3px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: #fff;
    }
    &::after {
      background-image: none;
      background-color: $c-precious;
    }
  }
}
