.search-field {
  &__input,
  & input.search-field__input {
    padding-left: 12px;
  }
}
.search-field,
.venueInput {
  position: relative;
  &__input,
  & input {
    height: 50px;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  &__icon,
  & .fa-search {
    position: absolute;
    left: 13px;
    transform: translate(0, -50%);
    background-color: transparent;
    color: $c-lighter-gray;
    font-size: 1.5rem;
  }
  &__icon,
  &__clear {
    top: 50%;
  }
  &__field {
    position: relative;
  }
  &__clear,
  & .btn {
    position: absolute;
    right: 5px;
    transform: translate(0, -50%);
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: $c-nevada;
    z-index: 5;

    &:hover {
      color: $c-nevada;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.venueInput {
  margin-bottom: 1rem;

  & .fa-search,
  & .btn {
    top: 25px;
  }
}
