.header {
  background: $c-ultralight-gray;
  height: 60px;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 1055; // modal z-index is 1050

  &__help-text {
    font-size: 13px;
    line-height: 1;
    @include media-breakpoint-up(md) {
      font-size: unset;
    }
  }
  &__help-num {
    font-size: 1.25rem;
    color: $c-dark;
    font-weight: 600;
  }
}

.sidebar-menu {
  display: flex;
  height: 100vh;
  width: 200px;
  background: $main-menu-bg;
  padding-top: 0;
  grid-area: sidebar;
  transition: width 0.5s;

  &:not(.show) {
    width: 72px;
  }

  & .sidebar-menu-collapse {
    width: 200px;
    position: relative;
    flex-basis: 100%;
    flex-grow: 1;
    padding: 0;
    color: $menu-item-font-color;

    & .main-menu {
      &__title {
        color: white;
        height: 84px;
        line-height: 84px;
        text-align: center;
      }

      &__logo {
        transition: all 0.5s;
        $logo-size: 84px;
        height: $logo-size;
        width: 100%;
      }
    }

    & .menu-wrapper {
      padding-bottom: 24px;
      height: calc(100vh - 82px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    &.show {
      width: 200px;
    }

    &:not(.show) {
      width: 72px;
      display: block;

      & .main-menu__title {
        & img {
          height: 17px;
          object-position: left;
          object-fit: cover;
        }
      }

      & .sidebar-menu-header {
        & .sidebar-menu-toggle {
          & span {
            &:before {
              content: "\F105";
              font-family: "Font Awesome 5 Pro", serif;
            }
          }
        }
      }

      & .menu-wrapper {
        & .sidebar-menu-body {
          & .sidebar-menu-nav a {
            & span {
              display: none;
            }

            & i {
              display: block;
            }
          }
        }

        & button {
          font-size: 0;
          letter-spacing: 0;
          text-align: center;

          &:before {
            font-size: 18px;
            content: "\F08B";
            font-family: "Font Awesome 5 Pro", serif;
          }
        }
      }
    }

    & .sidebar-menu-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 82px;

      & .sidebar-menu-toggle {
        border: 1px solid $main-menu-title-bg;
        background-color: $c-white;
        color: $main-menu-title-bg;
        border-radius: 3px;
        position: absolute;
        height: 2rem;
        width: 2rem;
        padding: 0.1rem;
        right: -37px;
        top: 5px;
        z-index: 11;
        transition: all 0.5s;

        & span {
          &:before {
            content: "\F104";
            font-family: "Font Awesome 5 Pro", serif;
          }
        }

        @extend .navbar-toggler;
      }
    }

    & .sidebar-menu-body {
      border-bottom: 1px solid #fff;
      border-top: 1px solid #fff;
      color: $menu-item-font-color;
      margin: 0 15px;
      padding: 0;

      & .sidebar-menu-nav a {
        position: relative;
        display: flex;
        height: 53px;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 0.875rem;
        color: white;
        border: none;
        outline: none;
        background: $main-menu-bg;
        transition: color 0.5s, background-color 0.5s;

        &:hover,
        &:focus {
          font-weight: 600;
          color: white;
          text-decoration: none;
          outline: none;
          border: none;
        }

        &.active {
          font-weight: 600;
        }

        & i {
          font-size: 18px;
          width: 40px;
          text-align: center;
          display: none;
        }
      }
    }

    & .menu-container__item--logout {
      font-size: 0.875rem;
      background: #00626b;
      color: #fff;
      bottom: 30px;
      width: 78px;
      text-align: center;
      padding: 15px;
      border: none;
      outline: none;
    }
  }
}
