h1,
.h1 {
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
    line-height: 1.5;
    font-weight: 300;
  }
  font-weight: 600;
}
h2,
.h2 {
  line-height: 1.45;
}
h3,
.h3 {
  line-height: 1.1;
  font-weight: 600;
}
h4,
.h4 {
  line-height: 1.5;
  font-weight: 400;
}
h5,
.h5 {
  line-height: 1.5;
  font-weight: 600;
}
h6,
.h6 {
  line-height: 1.63;
  font-weight: 600;
}

p {
  font-size: 16px;
}

a {
  font-weight: 600;
}

.list-font {
  font-size: 13px;
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.bold-1 {
  font-size: 1rem;
  font-weight: 600;
}

.label-small {
  font-size: 0.875rem;
}

.line-height-17 {
  line-height: 1.7;
}
