.popover {
  padding: 5px 2px 5px 5px;
}
// Hack to fix flickering popover bug from react bootstrap package
.bs-popover {
  &-right {
    left: 5px !important;
  }
  &-left {
    right: 5px !important;
  }
}

.bs-popover-auto {
  &[x-placement^="right"] {
    left: 5px !important;
  }
  &[x-placement^="left"] {
    right: 5px !important;
  }
}

.popover-content {
  font-size: inherit;
  padding: 1rem 1rem;
  min-width: 230px;
  box-shadow: -1px 2px 7px;
  color: $c-onebeacon-gray;
  border: none;
  top: 60px !important;
  right: -20px !important;

  a {
    font-weight: normal;
  }

  .arrow {
    display: none;
  }

  .popover-item {
    &__btn {
      font-weight: normal;
      font-family: inherit;
      color: $c-lagoon;
      padding: 0;
      white-space: nowrap;
      &:hover {
        color: $c-teal;
        text-decoration: underline;
      }
    }

    &__icon {
      min-width: 25px;
      text-align: center;
    }
  }
}
