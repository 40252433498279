.date-range-picker {
  input {
    &:read-only {
      color: #495057;
    }
  }
}

.rdp {
  padding: 16px;
  box-shadow: 0 40px 64px 0 rgba(174, 174, 174, 0.24);

  &-input-focus {
    border-color: $c-lagoon !important;
    box-shadow: 0 0 0 1px $c-lagoon;
  }

  &-head {
    border-bottom: 1px solid #e4e7e7 !important;

    &_cell {
      font-weight: normal !important;
      font-style: italic;
      text-transform: capitalize !important;
      font-size: 12px !important;
      color: #757575;
    }
  }

  &-nav_button {
    border: 1px solid #e4e7e7 !important;
    background-color: #fff !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 3px !important;

    &_previous {
      margin-right: 8px !important;
    }

    svg {
      width: 14px !important;
    }
  }

  &-day {
    border-radius: 0 !important;
    &:hover {
      background-color: transparent !important;
    }
    &-content {
      border-radius: 100% !important;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_selected:not(.rdp-day_disabled) {
      background-color: transparent !important;
      .rdp-day-content {
        background-color: $c-lagoon !important;
      }

      &:focus-visible {
        color: $white !important;
      }
    }

    &_selected.rdp-day_disabled {
      background-color: transparent !important;
      color: inherit !important;
      opacity: 0.25;
    }
    &_today:not(.rdp-day_selected) {
      .rdp-day-content {
        border: 2px solid #757575 !important;
        font-weight: normal;
      }
    }
    &:hover:not(.rdp-day_selected, .rdp-day_disabled),
    &_in-hover-range:not(.rdp-day_selected, .rdp-day_disabled) {
      .rdp-day-content {
        background-color: $c-jungle-mist !important;
        border-color: transparent !important;
      }
    }
  }

  &-day,
  &-button {
    &:focus-visible,
    &:focus {
      background-color: $c-jungle-mist !important;
      border-color: $c-lagoon !important;
      outline-color: $c-lagoon !important;
      border-radius: 100% !important;
      &.rdp-nav_button_next,
      &.rdp-nav_button_previous {
        border-radius: 0 !important;
        border-width: 2px !important;
      }
    }
  }
}
