.form-control--mw-125 {
  width: 125px;
  min-width: 100px;
}

.form--mb-100 {
  margin-bottom: 100px;
}

.form-group {
  &--w-160 {
    width: 160px;
  }

  &__user-input {
    position: relative;
  }
}

.form-error {
  &__user-input {
    position: absolute;
    top: -20px;
  }
}
