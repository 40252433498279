.autocomplete-dropdown-container {
  top: 100%;
  right: 0;
}
.suggestion {
  &-item {
    padding: 0.25rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.event-type-card {
  cursor: pointer;
}
