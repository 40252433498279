.autocomplete-dropdown-container {
  top: 100%;
  right: 0;
}
.suggestion,
.dropdown {
  &-item {
    transition: 0.2s color, 0.2s background;
    padding: 0.25rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    &--active {
      background: lighten($c-primary, 10%);
      color: white;
    }
    &.active {
      color: #16181b;
      text-decoration: none;
      background-color: #e9ecef;
    }
  }
}

.venueInput {
  & .autocomplete-dropdown-container {
    top: 105%;
  }
}
