.form-error {
  color: $c-danger;
  font-size: 80%;
  margin-bottom: 0.25rem;
  ~ input,
  ~ select,
  ~ .producerSelect div,
  ~ .input-group input,
  ~ .venueInput__field input {
    border-color: $c-danger;
  }

  &__label {
    color: $c-danger;
  }

  & a {
    color: $c-danger;
  }
}

.venue-code-form-error {
  color: $c-danger;
  border-color: $c-danger;
}
